import { ref } from 'vue'
import { defineStore } from 'pinia'

// eslint-disable-next-line import/prefer-default-export
export const useEnvStore = defineStore('environment', () => {
  const envName = ref(null)

  /**
   * Check if the env exist.
   *
   * @param environment
   */
  function setEnvironment(environment) {
    const availableEnvironments = ['front', 'app', 'extranet']

    if (availableEnvironments.includes(environment)) {
      envName.value = environment
    }

    envName.value = null
  }

  return {
    envName,
    setEnvironment,
  }
})
