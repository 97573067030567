import i18n from '@shared/i18n/vue-i18n'

import { useRouter } from 'vue-router'
import { useLoadingStore } from '@shared/store/loading'
import { useAuthStore } from '@shared/store/auth'

export default function useLogout(options = {}) {
  const router = useRouter()

  const authStore = useAuthStore()
  const loadingStore = useLoadingStore()
  /**
   * Redirect user to login.
   */
  function logout() {
    handleLogout(async () => {
      await router.push({ name: options.auth_route_name ?? 'login' })
    })
  }

  /**
   * Manually redirect user.
   *
   * @param url
   */
  async function guestLogout(url) {
    handleLogout(async () => {
      await router.push({
        redirect: window.location.href = url,
      })
    })
  }

  /**
   * Logout user and run action on success!
   *
   * @param callback
   */
  function handleLogout(callback) {
    loadingStore.startLoading(i18n.global.t('auth.logout.logging_out'))

    authStore.signOutFromApi()
      .then(async () => {
        await callback()
      })
      .finally(() => {
        loadingStore.reset()
      })
  }

  return {
    logout,
    guestLogout,
  }
}
