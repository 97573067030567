<template>
  <form
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors />

    <fieldset :disabled="submitting || spinnerStore.isActive">
      <user-geoloc-setting-form-fields
        :resource="resource"
      />

      <app-button
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        feature="validate"
        class="float-right mt-4"
      />
    </fieldset>
  </form>
</template>

<script setup>
import { useSpinnerStore } from '@shared/store/spinner'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import useForm from '@shared/hooks/form/form'
import useUserGeolocSetting from '@shared/hooks/userGeolocSetting'
import UserGeolocSettingFormFields from '@app/components/resources/user_geoloc_setting/UserGeolocSettingFormFields.vue'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const spinnerStore = useSpinnerStore()

function onSubmit() {
  // If geoloc "kind" was "around_me", and still is,
  // it means that user need to refresh coords fields with current position
  // before actually submitting
  if (
    props.resource.attributes.kind === 'around_me'
    && form.values.kind === 'around_me'
  ) {
    fillCoordsWithCurrentPosition()
      .then(() => {
        handleSubmit()
      })
  } else {
    handleSubmit()
  }
}

const {
  handleSubmit,
  invalid,
  form,
} = useForm(props, { emits })

const {
  fillCoordsWithCurrentPosition,
} = useUserGeolocSetting(props, { form })

</script>
