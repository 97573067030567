<template>
  <app-heading class="mb-3">
    {{ t('excursions.update.heading') }}
  </app-heading>

  <excursion-form
    v-if="excursion && !spinnerStore.isActive"
    :resource="excursion"
    :additional-errors="excursionFormErrors"
    :submitting="excursionFormSubmitting"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useFlashesStore } from '@shared/store/flashes'
import { useSpinnerStore } from '@shared/store/spinner'
import { fetchExcursion as fetchApiExcursion } from '@shared/http/api'
import useAppRouter from '@shared/hooks/router'
import useExcursionForm from '@shared/hooks/resources/form/excursion'
import AppHeading from '@app/components/ui/AppHeading.vue'
import ExcursionForm from '@app/components/resources/excursion/ExcursionForm.vue'

const appRouter = useAppRouter()
const route = useRoute()
const { t } = useI18n()
const spinnerStore = useSpinnerStore()
const flashesStore = useFlashesStore()

const excursion = ref({})

function fetchExcursion() {
  spinnerStore.enable()
  const { id } = route.params

  fetchApiExcursion(
    id,
    { 'mode': 'edit' },
  )
    .then((response) => {
      excursion.value = response.data.data
    })
    .finally(() => {
      spinnerStore.disable()
    })
}

const {
  errors: excursionFormErrors,
  submitting: excursionFormSubmitting,
  handleSubmit,
} = useExcursionForm({
  onSubmitSuccess,
  resource: excursion,
})

function onSubmitSuccess() {
  appRouter.back()
  flashesStore.addFlash({
    message: t('excursions.update.success'),
    type: 'success',
  })
}

fetchExcursion()
</script>
