import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

import filters from '@shared/filters'
import i18n from '@shared/i18n/vue-i18n'
import errorHandler from '@shared/errors/handler'
import interceptors from '@shared/http/interceptors'
import clickedOutside from '@shared/directives/clickedOutside'
import router from '@app/router/router'
import startApp from '@shared/startApp'
import routerGuards from '@shared/router/guards'
import store from '@app/store/store'
import TheApp from '@app/components/TheApp.vue'
import TeleportWrapper from '@shared/components/TeleportWrapper.vue'

import '@app/cookies'
import '@shared/broadcasting'
import '@shared/validation'
import '@shared/fontAwesome'

const app = createApp(TheApp)
const pinia = createPinia()

errorHandler(app)
interceptors(router, store)

app.config.globalProperties.$filters = filters

// Register global components
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('FontAwesomeLayers', FontAwesomeLayers)
app.component('TeleportWrapper', TeleportWrapper)

// Directives
app.directive('clicked-outside', clickedOutside)

routerGuards(router, store, {
  auth_route_name: 'login',
  home_route_name: 'home',
})

// Register vue modules
app.use(router)
app.use(store)
app.use(pinia)
app.use(i18n)

startApp(app, router, store)
  .finally()
