import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { login } from '@shared/http/api'
import { useAuthStore } from '@shared/store/auth'
import { useLoadingStore } from '@shared/store/loading'

export default function useLogin(options = {}) {
  const { t } = useI18n()

  const authStore = useAuthStore()
  const loadingStore = useLoadingStore()

  const formSubmitting = ref(false)
  const formErrors = ref({})

  function handleSubmit(attributes) {
    if (!formSubmitting.value) {
      formSubmitting.value = true

      login(attributes).then(async () => {
        // Loading is needed to hide the login transition
        // Otherwise, when logged, there is a short moment
        // when login form is shown with the full authenticated layout (navbar etc.)
        if (!options.noLoadingAfterLogin) {
          loadingStore.startLoading(t('auth.login.logging_in'))
        }

        await authStore.getAuthenticatedUser()

        if (!options.noRedirectAfterLogin) {
          await authStore.redirectAuthenticatedUser()
        }

        if (!options.noLoadingAfterLogin) {
          loadingStore.reset()
        }
      })
        .catch((e) => {
          if (e.response?.data?.errors) {
            formErrors.value = e.response.data.errors
          }
        })
        .finally(() => {
          formSubmitting.value = false
        })
    }
  }

  return {
    formSubmitting,
    formErrors,
    handleSubmit,
  }
}
