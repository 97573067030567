import {
  ref,
} from 'vue'
import { fromJS } from 'immutable'
import { defineStore } from 'pinia'
import { isEqual } from 'lodash'

// TODO: Change this rule in eslint!
// eslint-disable-next-line import/prefer-default-export
export const useFiltersStore = defineStore('filters', () => {
  // Readonly default filters
  const defaultFilters = {
    excursions: {
      kinds: ['customized', 'root', 'ugozer', 'tourist_office'],
      measurement_system: 'metric',
      limit_to_a_radius: false,
      radius: null,
    },
    pointsOfInterest: {
      certified: 'all',
      measurement_system: 'metric',
      limit_to_a_radius: false,
      radius: null,
      tags_mode: 'all',
      creator_id: 'no',
      tags_ids: [],
      countries: [],
      order: 'distance',
      point_of_interest_category_id: null,
    },
    supportRequests: {},
    payments: {},
    interactions: {},
  }

  const filters = ref({
    excursions: fromJS(defaultFilters.excursions).toJS(),
    pointsOfInterest: fromJS(defaultFilters.pointsOfInterest).toJS(),
    supportRequests: fromJS(defaultFilters.supportRequests).toJS(),
    payments: fromJS(defaultFilters.payments).toJS(),
    interactions: fromJS(defaultFilters.interactions).toJS(),
    default: fromJS(defaultFilters).toJS(),
  })

  /**
   * Set new filter for specific type (POI, excursions).
   *
   * @param payload
   */
  function setFilter(payload) {
    filters.value[payload.type][payload.name] = payload.value
  }

  /**
   * Set new default filter for specific type (POI, excursions).
   *
   * @param payload
   */
  function setDefault(payload) {
    filters.value.default[payload.type][payload.name] = payload.value
  }

  /**
   * Reset type's filters by default.
   *
   * @param type
   */
  function resetFilters(type) {
    filters.value[type] = defaultFilters[type]
  }

  /**
   * @param payload
   */
  function updateDefaultFilters(payload) {
    Object.entries(payload.values).forEach(([name, value]) => {
      setDefault({
        type: payload.type,
        name,
        value,
      })
    })

    if (payload.refresh) {
      Object.entries(payload.values).forEach(([name, value]) => {
        setFilter({
          type: payload.type,
          name,
          value,
        })
      })
    }
  }

  /**
   * Count number of active filter. Checked by difference with the default.
   *
   * @param type
   */
  function getFiltersCount(type) {
    let count = 0

    Object.entries(filters.value[type]).forEach(([name, value]) => {
      // Count how many filters are different from default ones
      if (!isEqual(value, filters.value.default[type][name])) {
        count += 1
      }
    })

    return ref(count)
  }

  return {
    filters,
    resetFilters,
    setFilter,
    updateDefaultFilters,
    getFiltersCount,
  }
})
