<template>
  <form
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors class="mb-5" />

    <fieldset :disabled="formSubmitting">
      <form-group
        name="password"
        type="password"
        rules="required"
        label-as-placeholder
        hide-errors
        :label="t('form.labels.user.new_password')"
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' }
        }"
      >
        <template #hint>
          <form-password-hint-list
            :password="form.values.password"
          />
        </template>
      </form-group>

      <form-group
        name="password_confirmation"
        type="password"
        label-as-placeholder
        rules="confirmed:@password"
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' }
        }"
      />

      <app-button
        feature="confirm"
        type="submit"
        width="full"
        :label="t('auth.password_reset.submit')"
        :disabled="formSubmitting || invalid"
        :loading="formSubmitting"
      />
    </fieldset>

    <div
      class="text-center mt-5 mb-6"
    >
      <router-link
        :to="{ name: 'auth' }"
        class="app-link"
      >
        {{ t('common.actions.cancel') }}
      </router-link>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import { useLoadingStore } from '@shared/store/loading'
import { useFlashesStore } from '@shared/store/flashes'
import { resetPassword } from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const authStore = useAuthStore()
const loadingStore = useLoadingStore()
const flashesStore = useFlashesStore()

const formSubmitting = ref(false)
const formErrors = ref({})

const {
  handleSubmit,
  form,
  invalid,
} = useForm({ additionalErrors: formErrors })

const uuid = route.params.uuid

async function onSubmit() {
  const fieldsToSubmit = await handleSubmit()

  if (fieldsToSubmit && !formSubmitting.value) {
    formSubmitting.value = true

    resetPassword(uuid, {
      data: {
        attributes: fieldsToSubmit,
      },
    })
      .then(async () => {
        loadingStore.startLoading(t('auth.login.logging_in'))
        await authStore.getAuthenticatedUser()
        await router.replace('/home')

        flashesStore.addFlash({
          message: t('auth.password_reset.success'),
          type: 'success',
        })
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          flashesStore.addFlash({
            message: t('exceptions.404'),
            type: 'danger',
          })
        }

        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        loadingStore.reset()
        formSubmitting.value = false
      })
  }
}
</script>
