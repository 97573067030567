import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

// TODO: Change this rule in eslint!
// eslint-disable-next-line import/prefer-default-export
export const useFlashesStore = defineStore('flashes', () => {
  const MAX_FLASHES_NB = 4
  const flashes = ref([])

  // Setters
  function addFlash(payload) {
    const duplicateIndex = flashes.value.findIndex((flash) => (flash.message === payload.message))

    // Do not add duplicated
    if (duplicateIndex === -1) {
      // Only keep the last messages, and remove the old ones
      if (flashes.value.length >= MAX_FLASHES_NB) {
        const flashesToRemoveNb = (flashes.value.length + 1) - MAX_FLASHES_NB
        flashes.value.splice(0, flashesToRemoveNb)
      }

      // Add the new flash message
      flashes.value.push({
        ...payload,
        timestamp: +new Date(),
      })
    }
  }

  function removeFlash(payload) {
    const index = flashes.value.findIndex((flash) => flash.timestamp === payload.timestamp)
    flashes.value.splice(index, 1)
  }

  const getFlashes = computed(() => flashes.value)

  return {
    addFlash,
    removeFlash,
    getFlashes,
  }
})
