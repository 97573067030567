<template>
  <app-button
    v-if="resource.authorizations.report"
    font-size="sm"
    feature="report"
    :icon-props="{ size: bigIcons ? 'text-4xl' : null }"
    :emphasis="emphasis"
    :hide-text="hideText"
    :hide-icon="hideIcon"
    @click="handleModalAction"
  />

  <!-- Modal -->
  <app-overlay
    :show="confirmModalDisplayed"
    @clicked="handleCancel"
  >
    <app-modal @closed="handleCancel">
      <template #title>
        <h4 class="text-2xl font-semibold">
          {{ t('points_of_interest.report.heading') }}
        </h4>
      </template>

      <p class="mb-4 text-left">
        {{ t(
          'points_of_interest.report.choose_reason',
          { point_of_interest_title: l10nTitle }
        ) }}
      </p>

      <report-form
        :resource="resource"
        :additional-errors="formErrors"
        :submitting="formSubmitting"
        @submitted="handleSubmit"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useFlashesStore } from '@shared/store/flashes'
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import ReportForm from '@shared/components/features/report/ReportForm.vue'
import {
  reportPointOfInterest,
} from '@shared/http/api'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Determine if button is text,
  // outline or contained
  emphasis: {
    type: String,
    default: 'high',
  },
  // Hide text or not
  hideText: {
    type: Boolean,
    default: false,
  },
  // Hide icon or not
  hideIcon: {
    type: Boolean,
    default: true,
  },
  // Display icons bigger
  bigIcons: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const flashesStore = useFlashesStore()

const confirmModalDisplayed = ref(false)
const formErrors = ref({})
const formSubmitting = ref(false)

function handleSubmit(attributes) {
  if (!formSubmitting.value) {
    formSubmitting.value = true
    const params = {
      data: {
        type: 'pointsofinterest',
        attributes: { ...attributes },
      },
    }

    reportPointOfInterest(props.resource.id, params)
      .then(() => {
        flashesStore.addFlash({
          message: t('points_of_interest.report.success'),
          type: 'success',
        })

        confirmModalDisplayed.value = false
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ---------- POI ----------

const { l10nTitle } = usePointOfInterest(props)

// Display Modal
function handleModalAction() {
  confirmModalDisplayed.value = true
}

// Cancel modal
function handleCancel() {
  confirmModalDisplayed.value = false
}
</script>
