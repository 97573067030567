<template>
  <app-overlay
    show
  >
    <app-modal
      :with-close-button="false"
    >
      <app-confirm
        :confirm-text="t('common.actions.accept')"
        :show-cancel-button="false"
        :confirm-loading="loading"
        @confirmed="handleUpdateConfirm"
      >
        <!-- Specify it's a new version, if policy previously accepted -->
        <p
          v-if="authStore.user?.attributes?.terms_condition_accepted"
          class="font-semibold mb-2"
        >
          {{ t('terms_conditions.update.new_version') }}
        </p>

        <p class="font-semibold mb-4">
          {{ t('terms_conditions.update.acceptation') }}
        </p>

        <!-- eslint-disable vue/no-v-html -->
        <div
          class="bg-white mb-5 p-2 text-justify h-60 overflow-y-scroll"
          v-html="l10nContent"
        />
      </app-confirm>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import useTermsCondition from '@shared/hooks/termsCondition'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'

const { t } = useI18n()
const authStore = useAuthStore()

const {
  l10nContent,
  updateTermsCondition,
  loading,
} = useTermsCondition()

function handleUpdateConfirm() {
  updateTermsCondition()
}
</script>
