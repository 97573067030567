<template>
  <excursion-show
    v-if="excursion && !loading"
    :resource="excursion"
    :folder-resource="travellerFolder"
    :destroy-callback="handleDestroy"
  />
  <excursion-show-empty v-else-if="loading" />
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useFlashesStore } from '@shared/store/flashes'
import {
  fetchLastTravellerTravellerFolder as apiFetchLastTravellerTravellerFolder,
  fetchExcursion as apiFetchExcursion,
  destroyExcursion,
} from '@shared/http/api'
import useAppRouter from '@shared/hooks/router'
import ExcursionShow from '@app/components/resources/excursion/ExcursionShow.vue'
import ExcursionShowEmpty from '@app/components/resources/excursion/ExcursionShowEmpty.vue'

const route = useRoute()
const appRouter = useAppRouter()
const { t } = useI18n()
const flashesStore = useFlashesStore()

const excursion = ref(null)
const loading = ref(false)

function fetchExcursion() {
  return new Promise((resolve) => {
    const { id } = route.params

    apiFetchExcursion(
      id,
      { 'mode': 'show' },
    )
      .then((response) => {
        excursion.value = response.data.data
      })
      .catch(() => {
        excursion.value = null
      })
      .finally(() => {
        resolve()
      })
  })
}

function handleDestroy() {
  return new Promise((resolve) => {
    loading.value = true

    destroyExcursion(excursion.value.id)
      .then(() => {
        appRouter.back()
        flashesStore.addFlash({
          message: t('excursions.destroy.success'),
          type: 'success',
        })
      })
      .finally(() => {
        loading.value = false
        resolve()
      })
  })
}

const travellerFolder = ref()

function fetchTravellerFolder() {
  return new Promise((resolve) => {
    apiFetchLastTravellerTravellerFolder()
      .then((response) => {
        travellerFolder.value = response.data.data
      })
      .catch(() => {
        travellerFolder.value = null
      })
      .finally(() => {
        resolve()
      })
  })
}

async function fetchResources() {
  loading.value = true

  await fetchExcursion()
  await fetchTravellerFolder()

  loading.value = false
}

onMounted(() => {
  fetchResources()
})
</script>
