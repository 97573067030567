<template>
  <div class="p-3">
    <router-link
      :to="{ name: 'show-users', params: { id: contact.id } }"
    >
      <div class="flex items-center">
        <app-rounded-picture
          :picture-url="pictureUrl"
          picture-size="12"
          :icon-name="iconName"
          icon-size="lg"
          with-shadow
          class="mr-5"
        />

        <div class="w-9/12">
          <p class="font-bold truncate">
            {{ contactDisplayName }}
          </p>

          <p
            v-if="poiL10nTitle"
            class="text-sm font-bold truncate"
          >
            {{ poiL10nTitle }}
          </p>

          <p class="text-sm truncate">
            {{ userRoles }}
          </p>
        </div>
      </div>
    </router-link>

    <users-relationship-actions
      v-if="!hideActions"
      class="flex justify-end items-center mt-1"
      :resource="resource"
      :big-icons="true"
      @contact-updated="handleContactUpdated"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { useAuthStore } from '@shared/store/auth'
import useUser from '@shared/hooks/resources/user'
import useResource from '@shared/hooks/resources/resource'
import useLocale from '@shared/hooks/locale'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import UsersRelationshipActions from '@app/components/resources/users_relationship/UsersRelationshipActions.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called
  // on update handling
  updateCallback: {
    type: Function,
    default: () => ({}),
  },
  // Hide actions
  hideActions: {
    type: Boolean,
    default: false,
  },
})
const authStore = useAuthStore()
const {
  getIconFromResourceType,
  getImageUrlFromResource,
  USER_TYPE,
} = useResource()

const { getAttributeTranslation } = useLocale()

const contact = computed(() => {
  const currentUserId = authStore.user.id

  return props.resource.relationships.sender.id === currentUserId
    ? props.resource.relationships.recipient
    : props.resource.relationships.sender
})

const {
  roles: userRoles,
  displayName: contactDisplayName,
} = useUser({ resource: contact.value })

const iconName = getIconFromResourceType(USER_TYPE)
const pictureUrl = computed(() => (
  getImageUrlFromResource(contact.value, 'xs')
))

const poiL10nTitle = computed(() => (
  getAttributeTranslation(contact.value?.attributes?.point_of_interest_title)
))

function handleContactUpdated() {
  props.updateCallback()
}
</script>
