import { useFlashesStore } from '@shared/store/flashes'
import { useAuthStore } from '@shared/store/auth'
import { useRouterStore } from '@shared/store/router'

export default ((router, options = {}) => {
  router.beforeEach((to, _, next) => {
    const flashesStore = useFlashesStore()
    const authStore = useAuthStore()
    const routerStore = useRouterStore()

    // Catch flash messages and display them
    if (to.query.danger) {
      flashesStore.addFlash({
        message: to.query.danger,
        type: 'danger',
      })
    } else if (to.query.success) {
      flashesStore.addFlash({
        message: to.query.success,
        type: 'success',
      })
    }

    const isAuthPage = to.path.startsWith('/auth')

    // Handle redirection on (un)authenticated users
    if (
      !isAuthPage
        && !authStore.isAuthenticated
    ) {
      // Redirect unauthenticated user to auth page
      next({ name: options.auth_route_name ?? 'login' })

      // Save initial path to redirect user after login
      routerStore.setAfterLoginRedirectRoute(to)
    } else if (
      isAuthPage
      && authStore.isAuthenticated
    ) {
      // Redirect authenticated user to home page
      next({ name: options.home_route_name ?? 'root' })
    } else {
      next()
    }
  })
})
