import { computed, ref } from 'vue'
import { uniq } from 'lodash'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import { distanceToDisplay as distanceToDisplayHelper } from '@shared/helpers/geoloc'
import useDatetime from '@shared/hooks/datetime'
import useLocale from '@shared/hooks/locale'
import useResource from '@shared/hooks/resources/resource'
import * as mapbox from '@shared/http/mapbox'

export default function usePointOfInterest(props = null) {
  const { t } = useI18n()

  const authStore = useAuthStore()

  const { getAttributeTranslation } = useLocale()
  const {
    getImageFromResource,
    getImageUrlFromResource,
    getIconFromResourceType,
    POI_TYPE,
  } = useResource()
  const { currentDate, addDaysToDate, diffInDays } = useDatetime()

  // ---------- MEDIA ----------

  const placeholderImage = '/images/shared/placeholders/pointsofinterest.png'

  const mainImage = computed(() => (
    getImageFromResource(props?.resource)
  ))

  function getMainImageUrl(version = 'sm') {
    return getImageUrlFromResource(props?.resource, version)
  }

  function getLogoUrl(version = 'sm') {
    return props?.resource.relationships?.logo?.attributes?.urls?.[version]
      ?? placeholderImage
  }

  function isPlaceholderImage(url) {
    return url === placeholderImage
  }

  const gallery = computed(() => props?.resource.relationships?.images)
  const audios = computed(() => props?.resource.relationships?.audios)
  const videos = computed(() => props?.resource.relationships?.videos)
  const logo = computed(() => props?.resource.relationships?.logo)

  // ---------- STATE ----------

  const stateToDisplay = computed(() => {
    const state = props?.resource.attributes.state

    if (state === 'pending') {
      return {
        icon: 'clock',
        text: t('states.point_of_interest_state.pending'),
      }
    }

    if (isDenied.value) {
      return {
        icon: 'times-circle',
        text: t('states.point_of_interest_state.denied'),
      }
    }

    if (state === 'draft') {
      return {
        icon: 'pen',
        text: t('states.point_of_interest_state.draft'),
      }
    }

    return null
  })

  // Display or not the excursions counter
  const displayExcursionsCounter = computed(() => (
    props?.resource.attributes.held_by_auth_user
      && props?.resource.attributes.excursions_counter
      && props?.resource.attributes.excursions_counter > 0
  ))

  const distanceToDisplay = computed(() => (
    distanceToDisplayHelper(
      authStore.user?.relationships?.geoloc_setting?.attributes?.measurement_system,
      props?.resource?.attributes?.distance,
    )
  ))

  const hasCoords = computed(() => (
    props?.resource.relationships.address?.attributes?.latitude
      && props?.resource.relationships.address?.attributes?.longitude
  ))

  // Draft with denial reason is assumed as denied
  const isDenied = computed(() => (
    props?.resource.attributes.denial_reason
      && props?.resource.attributes.state === 'draft'
  ))

  const l10nTitle = computed(() => (
    getAttributeTranslation(props?.resource.attributes.title, props?.resource.attributes.locale)
  ))

  const l10nDescription = computed(() => (
    getAttributeTranslation(props?.resource.attributes.description, false)
  ))

  const hasDTRequiredFields = computed(() => (
    props?.resource?.attributes?.dt_created_by && props?.resource?.attributes?.dt_last_update
  ))

  const iconName = getIconFromResourceType(POI_TYPE)
  const typingTimer = ref(0)
  const addressOptions = ref([])

  // Opening hours
  const isExpired = computed(() => (
    props?.resource?.attributes?.expiry_date
      && props?.resource?.attributes?.expiry_date <= currentDate(0)
  ))

  const isExpirable = computed(() => (
    props?.resource.relationships?.category?.attributes?.expirable
  ))

  const daysBeforeDeleting = computed(() => (
    countDaysBeforeDeleting(props?.resource.attributes.expiry_date)
  ))

  function handleAddressSearch(searchKeywords, toggleLoading) {
    // Add search keyword to available options
    // Do not use taggable prop, because it makes "filterable" as false not working
    const options = [{
      value: searchKeywords,
      label: searchKeywords,
      icon: 'pen',
    }]

    if (searchKeywords && searchKeywords.length >= 3) {
      if (typingTimer.value) {
        clearTimeout(typingTimer.value)
      }

      // Wait a little before call mapbox api
      // It allows user to finish typing, and avoid to trigger an api call at each keystroke
      typingTimer.value = setTimeout(() => {
        toggleLoading()

        mapbox.geocoding(searchKeywords, {
          types: [
            'address',
            'poi',
          ].join(','),
          language: authStore.user.attributes.locale,
        })
          .then((r) => {
            const newOptions = r.data.features
              ?.map((place) => {
                // only keep address line as value
                let value = ''
                if (place.address) {
                  value = `${place.address} `
                }
                value += place.text

                let icon
                if (place.place_type.includes('poi')) {
                  icon = 'map-marker-alt'
                } else if (place.place_type.includes('address')) {
                  icon = 'map-signs'
                }

                return {
                  subLabel1: value,
                  subLabel2: uniq(place.context?.map((c) => c.text)).join(', '),
                  label: value,
                  context: place.context,
                  coords: {
                    latitude: place.center[1],
                    longitude: place.center[0],
                  },
                  value,
                  icon,
                }
              })

            addressOptions.value = options.concat(newOptions || [])
          })
          .finally(() => {
            toggleLoading()
          })
      }, 400)
    } else if (!searchKeywords || searchKeywords.length === 0) {
      // Empty options
      addressOptions.value = []
    }
  }

  /**
   * Add days to given date
   *
   * @param expiryDate
   * @returns {object}
   */
  function countDaysBeforeDeleting(expiryDate) {
    // Event expired are deleted 21 days after last date
    const dateWhenDeleted = addDaysToDate(expiryDate, 21)
    const today = currentDate(0)

    return diffInDays(dateWhenDeleted, today)
  }

  // ---------- DUPLICATE RELEVANCE ----------

  const maxRelevanceScore = 16
  const relevantEnoughRatio = 0.3
  // A POI is relevant enough if its score is high enough compared to max one
  const relevantEnoughScore = maxRelevanceScore * relevantEnoughRatio

  const relevancePercentage = computed(() => (
    ((props?.resource.attributes.relevance ?? 0) / 16) * 100
  ))

  const relevanceCriteria = [
    'title',
    'phone',
    'email',
    'website',
    'address',
    'category',
  ]

  return {
    // Variables
    addressOptions,
    gallery,
    audios,
    videos,
    logo,
    relevantEnoughScore,
    relevanceCriteria,

    // Computed
    stateToDisplay,
    distanceToDisplay,
    l10nTitle,
    l10nDescription,
    hasDTRequiredFields,
    mainImage,
    hasCoords,
    displayExcursionsCounter,
    isExpired,
    isExpirable,
    daysBeforeDeleting,
    relevancePercentage,

    // Methods
    iconName,
    handleAddressSearch,
    getMainImageUrl,
    getLogoUrl,
    countDaysBeforeDeleting,
    isPlaceholderImage,
  }
}
