import { computed } from 'vue'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'

import { useAuthStore } from '@shared/store/auth'
import useResource from '@shared/hooks/resources/resource'

export default function useAuthUser() {
  const authStore = useAuthStore()

  const {
    getIconFromResourceType,
    getImageFromResource,
    USER_TYPE,
  } = useResource()

  const userIcon = getIconFromResourceType(USER_TYPE)

  // Authenticated user
  const authUser = computed(() => (
    authStore.user
  ))

  const avatarUrl = computed(() => (
    getImageFromResource(authUser.value)?.attributes?.urls?.xxs
  ))

  const hasGeolocSetting = computed(() => (
    !isNil(authUser.value?.relationships?.geoloc_setting?.attributes.latitude)
    && !isNil(authUser.value?.relationships?.geoloc_setting?.attributes.longitude)
  ))

  const countryCode = computed(() => {
    if (!hasGeolocSetting.value) {
      return null
    }

    return authUser.value?.relationships?.geoloc_setting?.attributes.country_code
  })

  // ------ SUBSCRIPTION ------

  const currentSubscription = computed(() => (
    authUser.value?.relationships.subscription
  ))

  const activeSubscription = computed(() => (
    currentSubscription.value?.attributes?.state === 'active' ? currentSubscription.value : null
  ))

  const upcomingSubscription = computed(() => (
    authUser.value?.relationships.upcoming_subscription
  ))

  const stillInFreePeriod = computed(() => (
    authUser.value.attributes.is_subscribed
      && DateTime.fromISO(currentSubscription.value?.attributes?.free_period_ended_at).toISODate() >= DateTime.now().toISODate()
  ))

  return {
    // Variables
    userIcon,

    // Computed
    authUser,
    avatarUrl,
    hasGeolocSetting,
    countryCode,
    stillInFreePeriod,
    currentSubscription,
    activeSubscription,
    upcomingSubscription,
  }
}
