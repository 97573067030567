import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

// TODO: Change this rule in eslint!
// eslint-disable-next-line import/prefer-default-export
export const useModalsStore = defineStore('modals', () => {
  const modals = reactive({
    excursion_form: {},
    point_of_interest_form: {},
    point_of_interest_show: {},
    user_show: {},
    geoloc_failed: {},
  })

  function show(modalName) {
    modals[modalName].display = true
  }

  function reset(modalName) {
    modals[modalName] = {}
  }

  function setProperties(payload) {
    const { name, ...properties } = payload

    modals[name] = {
      ...modals[name],
      ...properties,
    }
  }

  const getModals = computed(() => modals)

  return {
    modals,
    setProperties,
    show,
    reset,
    getModals,
  }
})
