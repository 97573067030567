import { computed, ref } from 'vue'
import { fromJS } from 'immutable'
import { useFiltersStore } from '@shared/store/filters'

/**
 * @param {string} type which filter's type (excursions, pointsofinterest...)
 * @param {object} form VeeValidate form
 * @param {object} options emits function, ...
 * @returns {object}
 */
export default function useFilters(type, form = ref(null), options = {}) {
  const filtersStore = useFiltersStore()

  function handleFilterChange(name, value) {
    // Sync form filters values with store

    filtersStore.setFilter({
      type,
      name,
      value,
    })
  }

  function handleFiltersApplied() {
    options.emits('filters-applied')
  }

  function handleResetFilters() {
    if (form.value) {
      // Apply default filters' values to each form value
      Object.entries(form.value.values).forEach(([name]) => {
        form.value.setFieldValue(name, defaultFilters.value[name])
      })
    }

    handleFiltersApplied()
  }

  // Retrieve default filters' values copy from store
  const defaultFilters = computed(() => (
    fromJS(filtersStore.filters.default[type]).toJS()
  ))

  // Filter's active if there is at least 1 filter different from default
  const hasActiveFilters = computed(() => (
    filtersStore.getFiltersCount(type) > 0
  ))

  const defaultRadiusValue = computed(() => (
    filtersStore.filters.excursions.radius
      || filtersStore.filters.pointsOfInterest.radius
      || 10
  ))

  return {
    handleFilterChange,
    handleFiltersApplied,
    handleResetFilters,
    hasActiveFilters,
    defaultRadiusValue,
  }
}
