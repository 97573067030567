import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { useAuthStore } from '@shared/store/auth'
import useResource from '@shared/hooks/resources/resource'

export default function useNotifications(props) {
  const router = useRouter()
  const authStore = useAuthStore()
  const {
    getRouteFromClassName,
    USER_CLASS_NAME,
  } = useResource()

  const sourceRoute = computed(() => {
    let route

    // Route depends on notification's type
    switch (props.type) {
      case 'App\\Notifications\\InstitutionEmployee\\PendingNotification':
        route = { name: 'index-institutions-employees' }
        break
      default:
        // Redirect to private profile if source is auth user
        if (
          props.source?.type === USER_CLASS_NAME
          && authStore.isAuthenticated
          && props.source.id === authStore.user.id
        ) {
          route = { name: 'show-profile' }
        } else {
          // Retrieve route from source's class & id
          route = getRouteFromClassName(props.source?.type, props.source?.id)
        }
    }

    // Check if the route exists
    const routesNames = router.getRoutes().map((rt) => (rt.name))
    if (routesNames.includes(route.name)) {
      return route
    }
    return {}
  })

  // Notification is clickable if there is a route on it
  const clickable = computed(() => sourceRoute.value?.name !== undefined)

  // Notification image or placeholder
  const imageUrlToUse = computed(() => (
    props.imageUrl ?? '/images/shared/placeholders/notifications.png'
  ))

  return {
    sourceRoute,
    clickable,
    imageUrlToUse,
  }
}
