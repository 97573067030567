<template>
  <transition
    appear
    name="fade"
  >
    <div
      v-if="spinnerStore.isActive"
      class="z-60 text-theme-500 h-full w-full flex flex-col items-center justify-center fixed top-0 left-0"
    >
      <app-spinner size="3x" />
    </div>
  </transition>
</template>

<script setup>
import { useSpinnerStore } from '@shared/store/spinner'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'

const spinnerStore = useSpinnerStore()
</script>
