import { ref } from 'vue'
import { defineStore } from 'pinia'

import { fetchLastTravellerTravellerFolder } from '@shared/http/api'

// TODO: Change this rule in eslint!
// eslint-disable-next-line import/prefer-default-export
export const useTravellerFoldersStore = defineStore('traveller_folders', () => {
  const activeFolder = ref(false)

  function getActiveFolder() {
    return new Promise((resolve) => {
      fetchLastTravellerTravellerFolder()
        .then((response) => {
          activeFolder.value = response.data.data
        })
        .finally(() => {
          resolve()
        })
    })
  }

  return {
    activeFolder,
    getActiveFolder,
  }
})
