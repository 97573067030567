<template>
  <div class="space-x-4">
    <template
      v-if="!isRelationSender && resource.attributes.state === 'pending'"
    >
      <app-button
        emphasis="low"
        feature="confirm"
        :icon-props="{ size: bigIcons ? 'xl' : null }"
        :label="t('common.actions.accept_contact')"
        :hide-text="true"
        :hide-icon="false"
        @click="handleModalAction('approve')"
      />

      <app-button
        emphasis="low"
        feature="close"
        :icon-props="{ size: bigIcons ? 'text-3xl' : null }"
        :label="t('common.actions.decline_contact')"
        :hide-text="true"
        :hide-icon="false"
        @click="handleModalAction('decline')"
      />
    </template>

    <app-button
      v-if="resource.attributes.state !== 'blocked'"
      emphasis="low"
      feature="block"
      :icon-props="{ size: bigIcons ? 'xl' : null }"
      :label="t('common.actions.block_contact')"
      :hide-text="true"
      :hide-icon="false"
      @click="handleModalAction('block')"
    />

    <app-button
      v-if="showUnblockButton"
      emphasis="low"
      icon-name="user-clock"
      :icon-props="{ size: bigIcons ? 'xl' : null }"
      :label="t('common.actions.unblock_contact')"
      :hide-text="true"
      :hide-icon="false"
      @click="handleModalAction('unblock')"
    />

    <!-- Modal actions -->
    <app-overlay
      :show="confirmModalDisplayed"
      @clicked="handleCancel"
    >
      <app-modal @closed="handleCancel">
        <app-confirm
          :confirm-color="colorButton"
          @cancelled="handleCancel"
          @confirmed="handleActionConfirm"
        >
          <p>
            {{ handleTextConfirm }}
          </p>
        </app-confirm>
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@shared/store/auth'
import { useSpinnerStore } from '@shared/store/spinner'
import { useFlashesStore } from '@shared/store/flashes'
import {
  approveContact as approveApiContact,
  declineContact as declineApiContact,
  blockContact as blockApiContact,
  unblockContact as unblockApiContact,
} from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Display icons bigger
  bigIcons: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'contactUpdated',
])

const { t } = useI18n()
const authStore = useAuthStore()
const spinnerStore = useSpinnerStore()
const flashesStore = useFlashesStore()

const handleActionConfirm = ref()
const handleTextConfirm = ref()
const confirmModalDisplayed = ref(false)
const actionConfirm = ref()
const colorButton = ref()

// ---------- APPROVE ----------

function approveContact() {
  spinnerStore.enable()

  approveApiContact(props.resource.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('users_relationships.messages.approve.success'),
        type: 'success',
      })
    })
    .finally(() => {
      spinnerStore.disable()

      emits('contactUpdated')
    })
}

// ---------- DECLINE ----------

function declineContact() {
  spinnerStore.enable()

  declineApiContact(props.resource.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('users_relationships.messages.decline.success'),
        type: 'success',
      })
    })
    .finally(() => {
      spinnerStore.disable()

      emits('contactUpdated')
    })
}

// ---------- BLOCK ----------

function blockContact() {
  spinnerStore.enable()

  blockApiContact(props.resource.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('users_relationships.messages.block.success'),
        type: 'success',
      })
    })
    .finally(() => {
      spinnerStore.disable()

      emits('contactUpdated')
    })
}

// ---------- UNBLOCK ----------

function unblockContact() {
  spinnerStore.enable()

  unblockApiContact(props.resource.id)
    .then(() => {
      flashesStore.addFlash({
        message: t('users_relationships.messages.unblock.success'),
        type: 'success',
      })
    })
    .finally(() => {
      spinnerStore.disable()

      emits('contactUpdated')
    })
}

const isRelationSender = computed(() => (
  props.resource.attributes.sender_id === authStore.user.id
))

const showUnblockButton = computed(() => (
  props.resource.attributes.state === 'blocked'
    && props.resource.attributes.blocked_by === authStore.user.id
))

// ---------- HANDLE MODAL ACTIONS ----------

// Define action according which button is clicked
function handleModalAction(action) {
  colorButton.value = action === 'approve' ? 'blue' : 'red'
  handleTextConfirm.value = t(`users_relationships.messages.${action}.confirm`)
  actionConfirm.value = action
  handleActionConfirm.value = handleConfirm
  confirmModalDisplayed.value = true
}

function handleConfirm() {
  switch (actionConfirm.value) {
    case 'approve':
      approveContact()
      break
    case 'decline':
      declineContact()
      break
    case 'block':
      blockContact()
      break
    case 'unblock':
      unblockContact()
      break
  }
  confirmModalDisplayed.value = false
}

// Cancel modal
function handleCancel() {
  confirmModalDisplayed.value = false
}
</script>
