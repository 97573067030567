import { ref } from 'vue'
import { defineStore } from 'pinia'
import { fetchDynamicLocales, fetchStaticLocales } from '@shared/http/api'

// eslint-disable-next-line import/prefer-default-export
export const useLocalesStore = defineStore('locales', () => {
  const statics = ref([])
  const dynamics = ref([])

  // Method
  async function getStaticLocales() {
    await fetchStaticLocales()
      .then(({ data }) => {
        statics.value = data.data
      })
  }

  async function getDynamicLocales() {
    await fetchDynamicLocales()
      .then(({ data }) => {
        dynamics.value = data.data
      })
  }

  return {
    statics,
    dynamics,
    getStaticLocales,
    getDynamicLocales,
  }
})
