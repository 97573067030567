import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { marked } from 'marked'
import DOMPurify from 'dompurify'

import { useTermsStore } from '@shared/store/terms'
import { useAuthStore } from '@shared/store/auth'
import { useFlashesStore } from '@shared/store/flashes'
import useLocale from '@shared/hooks/locale'
import { updateCurrentUserTermsCondition } from '@shared/http/api'

export default function useTermsCondition() {
  const termsStore = useTermsStore()
  const authStore = useAuthStore()
  const flashesStore = useFlashesStore()
  const { t } = useI18n()
  const { getAttributeTranslation } = useLocale()
  const loading = ref(false)

  const l10nContent = computed(() => {
    if (!termsStore.termsCondition) {
      return t('terms_conditions.errors.not_found')
    }

    const content = getAttributeTranslation(termsStore.termsCondition.attributes.content)

    return DOMPurify.sanitize(
      marked(content ?? ''),
      {},
    )
  })

  function updateTermsCondition() {
    loading.value = true

    const params = {
      data: {
        version: termsStore.termsCondition.attributes.version,
      },
    }

    updateCurrentUserTermsCondition(params)
      .then((response) => {
        authStore.setUser(response.data.data)
        flashesStore.addFlash({
          message: t('terms_conditions.update.success'),
          type: 'success',
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    l10nContent,
    updateTermsCondition,
    loading,
  }
}
