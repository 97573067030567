import { watch } from 'vue'
import { Interval, DateTime } from 'luxon'
import { isNil } from 'lodash'

import { useAuthStore } from '@shared/store/auth'
import { fetchCurrentUser } from '@shared/http/api'

export default function useSessionExpiration() {
  const authStore = useAuthStore()

  watch(() => authStore.sessionExpiredAt, (expiredAt) => {
    // Authenticated client-side
    if (expiredAt && !authStore.reAuthenticationNeeded) {
      // Get in how many ms the session will expire,
      // from expiredAt datetime
      const expirationInMs = Interval
        .fromDateTimes(
          DateTime.now(),
          expiredAt,
        )
        .length('milliseconds')

      if (authStore.sessionExpirationTimer) {
        authStore.clearSessionExpirationTimer()
      }

      authStore.setSessionExpirationTimer(setTimeout(async () => {
        // Check if really not authenticated server-side
        fetchCurrentUser()
          .then(({ data }) => {
            const serverAuthenticated = !isNil(data?.data?.id)
            if (!serverAuthenticated) {
              // Display re-authentication modal
              authStore.setReAuthenticationNeeded(true)
            }
          })
      }, expirationInMs))
    }
  })
}
