<template>
  <div
    class="flex flex-col bg-blue-100/50 shadow-lg shadow-theme-500/10 mb-4
      rounded-lg justify-center p-4 border-2 border-blue-300"
  >
    <p>
      {{ t('guest_traveller.messages.invite_to_register') }}
    </p>

    <app-button
      :label="t('guest_traveller.ui.buttons.register')"
      class="w-max mt-4"
      @click="handleLogout"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import { useTravellerFoldersStore } from '@app/store/folders'
import useLogout from '@shared/hooks/auth/logout'
import AppButton from '@shared/components/ui/AppButton.vue'

const foldersStore = useTravellerFoldersStore()
const { guestLogout } = useLogout()

const { t } = useI18n()

async function handleLogout() {
  guestLogout(foldersStore.getActiveFolder.relationships?.point_of_interest?.attributes?.quick_traveller_folder_url)
}
</script>
