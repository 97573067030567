import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

export default function useSelectOptions() {
  const { t } = useI18n()

  // ---------- COMMON ----------

  /**
   * Report reason type options
   *
   * @type {[{label: *, value: string}]}
   */
  const reasonTypeOptions = [
    { value: 'incorrect_data', label: t('enums.Report.ReasonTypeEnum.INCORRECT_DATA') },
    { value: 'shocking', label: t('enums.Report.ReasonTypeEnum.SHOCKING') },
    { value: 'illegal', label: t('enums.Report.ReasonTypeEnum.ILLEGAL') },
    { value: 'spam', label: t('enums.Report.ReasonTypeEnum.SPAM') },
    { value: 'other', label: t('enums.Report.ReasonTypeEnum.OTHER') },
  ]

  /**
   * Geoloc settings measure unit options
   *
   * @type {[{label: string, value: string}]}
   */
  const measureUnitOptions = [
    { value: 'metric', label: t('common.units.kilometer') },
    { value: 'imperial', label: t('common.units.mile') },
  ]

  /**
   * Reservation service options
   *
   * @type {[{label: string, value: string|null}]}
   */
  const serviceOptions = [
    { value: null, label: capitalize(t('common.none')) },
    { value: 'breakfast', label: t('enums.ReservationServiceEnum.BREAKFAST') },
    { value: 'lunch', label: t('enums.ReservationServiceEnum.LUNCH') },
    { value: 'dinner', label: t('enums.ReservationServiceEnum.DINNER') },
  ]

  /**
   * Point of interest certified options
   *
   * @type {[{label: string, value: string}]}
   */
  const certifiedOptions = [
    { value: 'yes', label: capitalize(t('common.yes')) },
    { value: 'no', label: capitalize(t('common.no')) },
    { value: 'all', label: capitalize(t('common.all')) },
  ]

  /**
   * Point of interest creator ID options
   *
   * @type {[{label: string, value: string}]}
   */
  const creatorIdOptions = [
    { value: 'yes', label: capitalize(t('common.yes')) },
    { value: 'no', label: capitalize(t('common.no')) },
  ]

  /**
   * Privacy settings options
   *
   * @type {[{label: string, value: string}]}
   */
  const privacySettingOptions = [
    { value: 'everyone', label: t('enums.PrivacySettingsEnum.EVERYONE') },
    { value: 'contacts', label: t('enums.PrivacySettingsEnum.CONTACTS') },
    { value: 'nobody', label: t('enums.PrivacySettingsEnum.NOBODY') },
  ]

  /**
   * Tip payable options
   *
   * @type {[{label: string, value: string}]}
   */
  const payableOptions = [
    { value: 'App\\Models\\Reservation', label: t('models.reservation') },
  ]

  /**
   * Audio player speed options
   *
   * @type {[{label: string, value: number}]}
   */
  const speedOptions = [
    { value: 0.75, label: t('common.slow') },
    { value: 1, label: t('common.normal') },
    { value: 1.25, label: t('common.fast') },
  ]

  // ---------- STATES ----------

  /**
   * Point of interest state options
   *
   * @type {[{label: string, value: string}]}
   */
  const poiStateOptions = [
    { value: 'draft', label: t('points_of_interest.states.draft') },
    { value: 'pending', label: t('points_of_interest.states.pending') },
    { value: 'published', label: t('points_of_interest.states.published') },
    { value: 'all', label: capitalize(t('common.all')) },
  ]

  /**
   * Support request state options
   *
   * @type {[{label: string, value: string}]}
   */
  const supportRequestStateOptions = [
    { value: 'pending-support', label: t('support_requests.states.pending-support') },
    { value: 'pending-user', label: t('support_requests.states.pending-user') },
    { value: 'canceled', label: t('support_requests.states.canceled') },
    { value: 'archived', label: t('support_requests.states.archived') },
    { value: 'resolved', label: t('support_requests.states.resolved') },
    { value: 'all', label: capitalize(t('common.all')) },
  ]

  /**
   * Excursion kind options
   *
   * @type {[{label: string, value: string}]}
   */
  const excursionKindOptions = [
    { value: 'customized', label: t('enums.Excursion.KindEnum.CUSTOMIZED') },
    { value: 'root', label: t('enums.Excursion.KindEnum.ROOT') },
  ]

  /**
   * Excursion kind options
   *
   * @type {[{label: string, value: string}]}
   */
  const excursionKindToOptions = [
    ...excursionKindOptions,
    { value: 'tourist_office', label: t('enums.Excursion.KindEnum.TOURIST_OFFICE') },
  ]

  /**
   * Excursion kind options
   *
   * @type {[{label: string, value: string}]}
   */
  const excursionKindFilterOptions = [
    { value: 'customized', label: t('enums.Excursion.KindEnum.CUSTOMIZED') },
    { value: 'root', label: t('enums.Excursion.KindEnum.ROOT') },
    { value: 'ugozer', label: t('enums.Excursion.KindEnum.UGOZER') },
    { value: 'tourist_office', label: t('enums.Excursion.KindEnum.TOURIST_OFFICE') },
  ]

  /**
   * Claim authorized countries
   *
   * @type {[{label: string, value: string}]}
   */
  const claimCountriesOptions = [
    { value: 'FR', label: t('enums.Claim.ClaimCountriesEnum.FRANCE') },
    { value: 'DE', label: t('enums.Claim.ClaimCountriesEnum.GERMANY') },
    { value: 'ES', label: t('enums.Claim.ClaimCountriesEnum.SPAIN') },
    { value: 'IT', label: t('enums.Claim.ClaimCountriesEnum.ITALY') },
    { value: 'TH', label: t('enums.Claim.ClaimCountriesEnum.THAILAND') },
  ]

  /**
   * Claim authorized countries
   *
   * @type {[{label: string, value: string}]}
   */
  const claimCompanyTypesOptions = [
    { value: 'individual', label: t('enums.Claim.ClaimCompanyTypeEnum.INDIVIDUAL') },
    { value: 'company', label: t('enums.Claim.ClaimCompanyTypeEnum.COMPANY') },
    { value: 'association', label: t('enums.Claim.ClaimCompanyTypeEnum.ASSOCIATION') },
    { value: 'other', label: t('enums.Claim.ClaimCompanyTypeEnum.OTHER') },
  ]

  /**
   * Tags filter mode
   *
   * @type {[{label: string, value: string}]}
   */
  const tagsModeOptions = [
    { value: 'all', label: t('points_of_interest.search.tags_mode.all') },
    { value: 'one', label: t('points_of_interest.search.tags_mode.one') },
  ]

  /**
   * Yes / no options
   *
   * @type {[{label: string, value: string}]}
   */
  const yesNoOptions = [
    { value: 'true', label: capitalize(t('common.yes')) },
    { value: 'false', label: capitalize(t('common.no')) },
  ]

  const defaultAvatars = [
    {
      value: 'male',
      label: capitalize(t('users.profile.avatar.defaults.male')),
      url: '/images/shared/placeholders/avatars/male.jpg',
    },
    {
      value: 'female',
      label: capitalize(t('users.profile.avatar.defaults.female')),
      url: '/images/shared/placeholders/avatars/female.jpg',
    },
  ]

  /**
   * Portrait / landscape options
   *
   * @type {[{label: string, value: string}]}
   */
  const displayOrientationOptions = [
    {
      value: 'portrait',
      label: t('enums.BroadcastPage.DisplayEnum.PORTRAIT'),
    },
    {
      value: 'landscape',
      label: t('enums.BroadcastPage.DisplayEnum.LANDSCAPE'),
    },
  ]

  return {
    // Common
    reasonTypeOptions,
    measureUnitOptions,
    serviceOptions,
    certifiedOptions,
    creatorIdOptions,
    privacySettingOptions,
    payableOptions,
    speedOptions,
    tagsModeOptions,
    yesNoOptions,

    // Excursion
    excursionKindOptions,
    excursionKindFilterOptions,
    excursionKindToOptions,

    // States
    poiStateOptions,
    supportRequestStateOptions,

    // Claim
    claimCountriesOptions,
    claimCompanyTypesOptions,

    // User
    defaultAvatars,

    // Display orientation
    displayOrientationOptions,
  }
}
