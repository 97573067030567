import Vueform from '@vueform/vueform'

// Pinia's stores
import { useLoadingStore } from '@shared/store/loading'
import { useAuthStore } from '@shared/store/auth'
import { useTermsStore } from '@shared/store/terms'
import { useLocalesStore } from '@shared/store/locales'
import { useRouterStore } from '@shared/store/router'

import vueformConfig from '../../../vueform.config'

export default (async (app, router) => {
  app.use(Vueform, vueformConfig)
  const loadingStore = useLoadingStore()
  const authStore = useAuthStore()
  const termsStore = useTermsStore()
  const localesStore = useLocalesStore()
  const routerStore = useRouterStore()

  loadingStore.startLoading()

  await router.isReady()
  // Render app after router loading
  app.mount('#app')

  // Set router in store
  routerStore.setRouter(router)

  // Retrieve available locales
  await localesStore.getStaticLocales()
  await localesStore.getDynamicLocales()

  // Retrieve last versions of
  // privacy policy and terms and conditions
  await termsStore.getLastTerm()

  // Handle authenticated state
  await authStore.getAuthenticatedUser()

  const currentUrl = window.location.hostname

  if (
    currentUrl.includes(import.meta.env.VITE_APP_SUBDOMAIN)
    || currentUrl.includes(import.meta.env.VITE_PRO_SUBDOMAIN)
  ) {
    if (authStore.isAuthenticated) {
      await authStore.redirectAuthenticatedUser()
    }
  }

  loadingStore.reset()
})
