import { ref } from 'vue'
import { defineStore } from 'pinia'

// eslint-disable-next-line import/prefer-default-export
export const useRouterStore = defineStore('router', () => {
  const mainKey = ref(0)
  const afterLoginRedirectRoute = ref(null)
  const router = ref(null)

  // Mutators
  function refreshMainKey() {
    mainKey.value += 1
  }

  function setRouter(value) {
    router.value = value
  }

  function setAfterLoginRedirectRoute(value) {
    afterLoginRedirectRoute.value = value
  }

  return {
    mainKey,
    afterLoginRedirectRoute,
    router,
    setAfterLoginRedirectRoute,
    refreshMainKey,
    setRouter,
  }
})
