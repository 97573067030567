import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import i18n from '@shared/i18n/vue-i18n'

export default function useLogout(options = {}) {
  const store = useStore()
  const router = useRouter()

  /**
   * Redirect user to login.
   */
  function logout() {
    handleLogout(() => router.push({ name: options.auth_route_name ?? 'login' }))
  }

  /**
   * Manually redirect user.
   *
   * @param url
   */
  async function guestLogout(url) {
    store.commit('loading/SET_TEXT', i18n.global.t('auth.logout.logging_out'))
    await store.dispatch('auth/signOutFromApi')
    await router.push({
      redirect: window.location.href = url,
    })
  }

  /**
   * Logout user and run action on success!
   *
   * @param callback
   */
  async function handleLogout(callback) {
    store.commit('loading/SET_TEXT', i18n.global.t('auth.logout.logging_out'))
    store.commit('loading/ENABLE')
    await store.dispatch('auth/signOutFromApi')
    if (callback) {
      await callback()
    }
    store.commit('loading/RESET')
  }

  return {
    logout,
    guestLogout,
  }
}
