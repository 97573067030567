<template
  v-if="geolocSettings"
>
  <div>
    <div
      v-if="guestTravellerAccess"
      class="flex"
    >
      <AppIcon
        library="coolicon"
        name="location_outline"
      />

      <p>{{ geolocName }}</p>
    </div>

    <app-button
      v-else
      display-classes="block"
      :icon-props="{ library: 'coolicon', name: 'location_outline', classes: ['-mr-1'] }"
      :hide-icon="false"
      emphasis="low"
      font-size="sm"
      :label="geolocName"
      :uppercase="false"
      padding=""
      font-weight="font-normal"
      class="truncate max-w-full"
      @click="toggleGeolocDisplay"
    />

    <transition
      appear
      name="bottom-bar"
    >
      <aside
        v-if="showGeoloc"
        class="z-40 fixed bottom-0 left-0 w-screen h-screen-4/5 shadow bg-body p-4 overflow-y-scroll"
      >
        <div class="flex flex-col justify-between">
          <div class="flex justify-between items-center mb-4">
            <app-button
              feature="close"
              :hide-icon="false"
              :hide-text="true"
              color="theme"
              emphasis="low"
              padding="py-1"
              @click="toggleGeolocDisplay"
            />
          </div>

          <user-geoloc-setting-form
            v-if="geolocSettings"
            :resource="geolocSettings"
            :additional-errors="geolocSettingsFormErrors"
            :submitting="geolocSettingsFormSubmitting"
            @submitted="handleSubmit"
          />
        </div>
      </aside>
    </transition>

    <app-overlay
      :show="showGeoloc"
      z-index="20"
      @clicked="toggleGeolocDisplay"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { truncate } from 'lodash'

import { useFlashesStore } from '@shared/store/flashes'
import { useAuthStore } from '@shared/store/auth'
import { updateGeolocSetting } from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import useAccess from '@extranet/hooks/access'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import UserGeolocSettingForm from '@app/components/resources/user_geoloc_setting/UserGeolocSettingForm.vue'

const emits = defineEmits([
  'submitted',
])

const authStore = useAuthStore()
const flashesStore = useFlashesStore()
const { t } = useI18n()
const { guestTravellerAccess } = useAccess()
const { getAttributeTranslation } = useLocale()

// ---------- GEOLOC ----------

const showGeoloc = ref(false)

const geolocSettings = computed(() => (
  authStore.user.relationships?.geoloc_setting
))

const geolocName = computed(() => {
  switch (geolocSettings.value?.attributes?.kind) {
    case 'around_me':
      return t('enums.UserGeolocSetting.KindEnum.AROUND_ME')
    case 'staying_hotel':
      return getAttributeTranslation(authStore.user.relationships?.staying_hotel?.attributes?.title)
    case 'customized':
      return truncate(geolocSettings.value.attributes.filter, {
        length: 30,
        separator: ' ',
      })
    default:
      return t('common.not_specified')
  }
})

function toggleGeolocDisplay() {
  showGeoloc.value = !showGeoloc.value
}

// ---------- FORM ----------

const geolocSettingsFormSubmitting = ref(false)
const geolocSettingsFormErrors = ref({})

function handleSubmit(attributes) {
  if (!geolocSettingsFormSubmitting.value) {
    geolocSettingsFormSubmitting.value = true

    const params = {
      data: {
        type: 'usergeolocsettings',
        attributes: { ...attributes },
      },
    }

    updateGeolocSetting(params)
      .then((response) => {
        flashesStore.addFlash({
          message: t('user_geoloc_settings.update.success'),
          type: 'success',
        })

        // Save user geoloc setting in store
        const updatedUser = authStore.user
        updatedUser.relationships.geoloc_setting = response.data.data
        authStore.setUser(updatedUser)

        afterSubmit()
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          geolocSettingsFormErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        geolocSettingsFormSubmitting.value = false
      })
  }
}

function afterSubmit() {
  toggleGeolocDisplay()
  emits('submitted')
}
</script>
