import i18n from '@shared/i18n/vue-i18n'
import { flatten } from 'flat'
import Cookies from 'js-cookie'

import { sanctumCsrfCookie, sanctumCsrfCookieUrl } from '@shared/http/api'
import { useAuthStore } from '@shared/store/auth'
import { useLoadingStore } from '@shared/store/loading'
import { useRouterStore } from '@shared/store/router'
import { useFlashesStore } from '@shared/store/flashes'

export default () => {
  const authStore = useAuthStore()
  const loadingStore = useLoadingStore()
  const routerStore = useRouterStore()
  const flashesStore = useFlashesStore()

  // Handlers types (by status or errors messages)
  const alreadyLogged = (error) => (error?.response?.status === 409 && error?.response?.data?.already_logged && authStore.isAuthenticated)
  const unauthorized = (error) => (error?.response?.status === 401 && authStore.isAuthenticated)
  const credentialNotVerified = (error) => (error?.response?.status === 403 && !authStore.user.attributes.has_verified_credential)

  const currentUrl = window.location.hostname

  if (
    currentUrl.includes(import.meta.env.VITE_APP_SUBDOMAIN)
    || currentUrl.includes(import.meta.env.VITE_PRO_SUBDOMAIN)
  ) {
    // Server refresh session expiration at each request
    authStore.refreshSessionExpiration()
  }
  // ---------- Privates methods ----------

  function generateErrorMessage(error) {
    let errorToDisplay = 'default'
    let errorMessage = null

    // Pick error message directly from the error's response
    if (error?.response?.data?.danger) {
      errorMessage = error?.response?.data?.danger
    } else if (error?.response) {
      // 422 : let the form handle the errors display
      // 401 : let the user be redirect to login form
      // 404 : let app handle what is rendered to the user
      // Credential not verified : let app handle what is rendered to the user
      if (
        [401, 422, 404].includes(error.response.status)
        || credentialNotVerified(error)
      ) return

      if (i18n.global.te(`exceptions.${error.response.status}`)) {
        errorToDisplay = error.response.status
      }
    }

    if (!errorMessage) {
      errorMessage = i18n.global.t(`exceptions.${errorToDisplay}`)
    }

    flashesStore.addFlash({
      message: errorMessage,
      type: 'danger',
    })
  }

  // Convert a JS object to a FormData object
  function objectToFormData(object) {
    const data = new FormData()

    Object.entries(flatten(object)).forEach(([fieldName, fieldValue]) => {
      fieldName = fieldName.split('.').map((fieldNamePart, index) => {
        // Convert JSON key to valid formdata key,
        // e.g.: 'first.second.third' => 'first[second][third]'
        if (index !== 0) {
          return `[${fieldNamePart}]`
        }

        return fieldNamePart
      })
        .join('')

      // Since value will be converted to string,
      // the API will interpret "1" and "0" with their correct bool value
      if ([true, false].includes(fieldValue)) {
        fieldValue = fieldValue ? 1 : 0
      } else if (fieldValue === null) {
        // A null value must be sent as an empty string
        fieldValue = ''
      } else if (typeof fieldValue === 'undefined') {
        // Undefined value must not be sent e.g.: an empty file input
        return
      }

      data.append(fieldName, fieldValue)
    })

    return data
  }

  // ---------- Exported methods ----------
  function handleResponseSuccess(response) {
    const currentUrl = window.location.hostname

    if (currentUrl.includes('app') || currentUrl.includes('pro')) {
      // Server refresh session expiration at each request
      authStore.refreshSessionExpiration()
    }

    return response
  }

  function handleResponseFailure(error) {
    if (alreadyLogged(error)) {
      loadingStore.startLoading()

      authStore.getAuthenticatedUser()
        .then(async () => {
          await routerStore.router.push('/')
        })
        .finally(() => {
          loadingStore.reset()
        })
    }

    if (unauthorized(error)) {
      // Display re-authentication modal
      authStore.setReAuthenticationNeeded(true)
    }

    generateErrorMessage(error)

    return Promise.reject(error)
  }

  async function handleRequestSuccess(config) {
    // If original request is not to fetch the csrf cookie
    // and if the csrf cookie is missing (assumed to be expired)
    if (
      sanctumCsrfCookieUrl !== config.url
      && !Cookies.get(config.xsrfCookieName)
    ) {
      // Refresh the csrf cookie
      await sanctumCsrfCookie()
    }

    // Convert object data to FormData
    if (config.data) {
      if (config.method === 'patch') {
        config.data._method = config.method.toUpperCase() // specify the method in the request's body
        config.method = 'post' // Laravel API only accepts POST method when Content-Type is multipart/formdata
      }
      config.data = objectToFormData(config.data)
    }

    return config
  }

  function handleRequestFailure(error) {
    return Promise.reject(error)
  }

  return {
    handleResponseSuccess,
    handleResponseFailure,
    handleRequestSuccess,
    handleRequestFailure,
  }
}
