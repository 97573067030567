<template>
  <template v-if="loading">
    <the-loading />
  </template>

  <template
    v-else
  >
    <the-header v-if="displayHeader" />

    <main
      :class="`max-w-screen-lg px-4 pb-6 mx-auto ${displayHeader ? 'with-header' : ''} ${displayNavbar ? 'with-navbar' : ''}`"
    >
      <the-error-boundary>
        <the-forbidden
          v-if="displayForbidden"
        />

        <router-view
          v-else
          :key="mainKey"
        />
      </the-error-boundary>
    </main>

    <footer class="sticky bottom-0 w-full z-20">
      <the-navbar v-if="displayNavbar" />
    </footer>

    <!-- Modal are ordered in reverse order of priority -->
    <the-update-privacy-policy-modal
      v-if="displayUpdatePrivacyPolicyModel"
    />

    <the-update-terms-condition-modal
      v-if="displayUpdateTermsConditionModel"
    />

    <the-password-missing-modal
      v-if="displayPasswordMissing"
    />

    <the-credential-not-verified-modal
      v-if="displayCredentialNotVerified"
    />

    <the-geoloc-failed-modal
      v-if="displayGeolocFailed"
    />
  </template>

  <the-flash-list />

  <the-spinner />

  <the-reauthentication-modal
    v-if="authStore.isAuthenticated && useAuthStore.reAuthenticationNeeded"
  />
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'

import { useTermsStore } from '@shared/store/terms'
import { useAuthStore } from '@shared/store/auth'
import { useLoadingStore } from '@shared/store/loading'
import { useRouterStore } from '@shared/store/router'
import { useModalsStore } from '@shared/store/modals'

import useSessionExpiration from '@shared/hooks/auth/sessionExpiration'
import TheHeader from '@app/components/layout/TheHeader.vue'
import TheSpinner from '@app/components/layout/TheSpinner.vue'
import TheNavbar from '@app/components/layout/navbar/TheNavbar.vue'
import TheFlashList from '@shared/components/features/flashes/TheFlashList.vue'
import TheReauthenticationModal from '@app/components/layout/modals/TheReauthenticationModal.vue'
import TheLoading from '@app/components/layout/TheLoading.vue'
import TheErrorBoundary from '@app/components/TheErrorBoundary.vue'
import TheUpdatePrivacyPolicyModal from '@app/components/layout/modals/TheUpdatePrivacyPolicyModal.vue'
import TheUpdateTermsConditionModal from '@app/components/layout/modals/TheUpdateTermsConditionModal.vue'
import TheCredentialNotVerifiedModal from '@app/components/layout/modals/TheCredentialNotVerifiedModal.vue'
import ThePasswordMissingModal from '@app/components/layout/modals/ThePasswordMissingModal.vue'
import TheGeolocFailedModal from '@app/components/layout/modals/TheGeolocFailedModal.vue'
import TheForbidden from '@app/components/layout/errors/TheForbidden.vue'

const termsStore = useTermsStore()
const authStore = useAuthStore()
const loadingStore = useLoadingStore()
const routerStore = useRouterStore()
const modalsStore = useModalsStore()
const route = useRoute()
useSessionExpiration()

const loading = computed(() => loadingStore.active)

// ---------- DISPLAY LAYOUT ----------

// Adapt the layout when some errors are displayed
const errorLayout = computed(() => (
  displayForbidden.value
))

const displayForbidden = computed(() => (
  authStore.isAuthenticated.value && !authStore.user.authorizations.access.app
))
const displayCredentialNotVerified = computed(() => (
  authStore.isAuthenticated.value && !authStore.user.attributes.has_verified_credential
))

const displayPasswordMissing = computed(() => (
  authStore.isAuthenticated.value && !authStore.user.attributes.has_password
))

// Navbar not needed while not authenticated
// Or on error layout
const displayNavbar = computed(() => (
  authStore.isAuthenticated
  && !errorLayout.value
))

const headerNotNeededRoutesNames = [
  'login',
  'register',
]

// Header not needed on some routes
// Or on error layout
const displayHeader = computed(() => (
  !headerNotNeededRoutesNames.includes(route.name)
  && !errorLayout.value
))

// ---------- ROUTER KEY ----------

const mainKey = computed(() => (
  routerStore.mainKey
))

// ---------- UPDATE PRIVATE POLICY ----------

const displayUpdatePrivacyPolicyModel = computed(() => (
  authStore.isAuthenticated.value
  && (
    !authStore.user.value.attributes.privacy_policy_accepted
    || authStore.user.value.attributes?.privacy_policy_version !== termsStore.privacyPolicy.value.attributes.version
  )
))

// ---------- UPDATE TERMS CONDITION ----------

const displayUpdateTermsConditionModel = computed(() => (
  authStore.isAuthenticated.value
  && (
    !authStore.user.attributes.terms_condition_accepted
    || authStore.user.attributes.terms_condition_version !== termsStore.termsCondition.attributes.version
  )
))

// ---------- GEOLOC FAILED ----------

const displayGeolocFailed = computed(() => (
  modalsStore.getModals.geoloc_failed.display
))
</script>
