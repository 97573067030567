import { ref } from 'vue'
import { defineStore } from 'pinia'

// eslint-disable-next-line import/prefer-default-export
export const useLoadingStore = defineStore('loading', () => {
  const active = ref(false)
  const text = ref(null)

  function reset() {
    active.value = false
    text.value = null
  }

  function startLoading(loadingText = null) {
    active.value = true

    if (loadingText) {
      text.value = loadingText
    }
  }

  return {
    active,
    text,
    reset,
    startLoading,
  }
})
