import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

// eslint-disable-next-line import/prefer-default-export
export const useHeaderStore = defineStore('header', () => {
  // ref = store's properties
  const transparentBackground = ref(false)

  // computed = getters
  const getTransparentBackground = computed(() => transparentBackground.value)

  // functions / methods are actions
  const setTransparentBackground = (value) => {
    transparentBackground.value = value
  }

  return {
    transparentBackground,
    setTransparentBackground,
    getTransparentBackground,
  }
})
