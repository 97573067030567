import { computed } from 'vue'
import i18n from '@shared/i18n/vue-i18n'
import { capitalize } from 'lodash'

import useLocale from '@shared/hooks/locale'
import { sendUserLink } from '@shared/http/api'
import {
  fullName as fullNameHelper,
  displayName as displayNameHelper,
} from '@shared/helpers/user'
import { useFlashesStore } from '@shared/store/flashes'

export default function useUser(props) {
  const flashesStore = useFlashesStore()
  const { getAttributeTranslation } = useLocale()

  const user = computed(() => (
    props.resource
  ))

  const userAttributes = computed(() => (
    user.value?.attributes
  ))

  const fullName = computed(() => (fullNameHelper(user.value)))
  const displayName = computed(() => (displayNameHelper(user.value)))

  // User's display name, with institution's title appended
  const displayNameWithInstitution = computed(() => {
    if (!displayName.value) {
      return null
    }

    if (getAttributeTranslation(userAttributes.value?.point_of_interest_title)) {
      const translatedPoiTitle = getAttributeTranslation(userAttributes.value.point_of_interest_title)
      return `${displayName.value} (${translatedPoiTitle})`
    }

    return displayName.value
  })

  // User roles to display
  const roles = computed(() => {
    const rawRoles = user.value?.attributes?.role_names || []
    const separator = i18n.global.t('common.and')
    let formattedRoles = null

    if (rawRoles.length > 1) {
      formattedRoles = `${rawRoles.slice(0, rawRoles.length - 1).join(', ')} ${separator} ${rawRoles.slice(-1)}`
    } else {
      formattedRoles = rawRoles[0]
    }

    return capitalize(formattedRoles)
  })

  const email = computed(() => (
    user.value?.attributes.email
  ))

  const showEmail = computed(() => (
    email.value !== displayName.value
  ))

  function invitationStatusBadgeDesign(state) {
    switch (state) {
      case 'pending':
        return 'warning'
      case 'validated':
        return 'success'
      default:
        return 'default'
    }
  }

  function handleSentCallback(value) {
    sendUserLink(value)
      .then(() => {
        flashesStore.addFlash({
          message: i18n.global.t('users.send_link.success'),
          type: 'success',
        })
      })
      .catch((e) => {
        if (e.response.status === 400) {
          flashesStore.addFlash({
            message: i18n.global.t('users.send_link.error'),
            type: 'danger',
          })
        }
      })
  }

  return {
    displayName,
    displayNameWithInstitution,
    fullName,
    roles,
    email,
    showEmail,
    invitationStatusBadgeDesign,
    handleSentCallback,
  }
}
