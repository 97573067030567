<template>
  <div class="flex items-center w-full">
    <p
      class="truncate w-full"
    >
      {{ text }}
    </p>

    <app-icon
      library="local"
      name="copy"
      :classes="['cursor-pointer', leftMargin, 'text-theme-500']"
      :size="size"
      @click="copy"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import useClipboard from 'vue-clipboard3'

import { useFlashesStore } from '@shared/store/flashes'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Text to display
  text: {
    type: String,
    default: null,
  },
  // Size
  size: {
    type: String,
    default: null,
  },
  // Margin left
  leftMargin: {
    type: String,
    default: 'ml-1',
  },
})

const { t } = useI18n()
const flashesStore = useFlashesStore()

const { toClipboard } = useClipboard()

const copy = async () => {
  try {
    await toClipboard(props.text.trim())
    flashesStore.addFlash({
      message: t('common.messages.clipboard.success'),
      type: 'success',
    })
  } catch (e) {
    flashesStore.addFlash({
      message: t('common.messages.clipboard.error'),
      type: 'danger',
    })
  }
}
</script>
