<template>
  <div
    class="above-navbar mx-auto max-w-screen-md py-2 px-4 z-60 fixed inset-x-0 bottom-0 pointer-events-none"
  >
    <transition-group
      name="flash"
      appear
    >
      <flash-item
        v-for="flash in flashes"
        :key="flash.timestamp"
        v-bind="flash"
        class="relative top-0 left-0 pointer-events-auto"
      />
    </transition-group>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { useFlashesStore } from '@shared/store/flashes'
import FlashItem from '@shared/components/features/flashes/FlashItem.vue'

const flashesStore = useFlashesStore()

const flashes = computed(() => (
  flashesStore.getFlashes.map((flash) => (
    {
      ...flash,
      closeCallback: () => handleFlashClose(flash.timestamp),
    }
  ))
))

function handleFlashClose(timestamp) {
  flashesStore.removeFlash({ timestamp })
}
</script>
