import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

// TODO: Change this rule in eslint!
// eslint-disable-next-line import/prefer-default-export
export const useSpinnerStore = defineStore('spinner', () => {
  const active = ref(false)

  const isActive = computed(() => active.value)

  // Setters
  function enable() {
    active.value = true
  }

  function disable() {
    active.value = false
  }

  return {
    enable,
    disable,
    isActive,
  }
})
