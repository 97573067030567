import flashes from '@shared/store/modules/flashes'
import auth from '@shared/store/modules/auth'
import router from '@shared/store/modules/router'
import loading from '@shared/store/modules/loading'
import filters from '@shared/store/modules/filters'
import terms from '@shared/store/modules/terms'
import locales from '@shared/store/modules/locales'
import spinner from '@shared/store/modules/spinner'
import modals from '@shared/store/modules/modals'
import home from '@app/store/modules/home'
import header from '@app/store/modules/header'
import folders from '@app/store/modules/folders'

export default {
  state: {
    appName: 'app',
  },
  modules: {
    folders,
    flashes,
    auth,
    router,
    spinner,
    loading,
    home,
    header,
    filters,
    terms,
    locales,
    modals,
  },
}
