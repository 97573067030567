import { ref } from 'vue'
import { defineStore } from 'pinia'

import { useEnvStore } from '@shared/store/environment'
import { useFiltersStore } from '@shared/store/filters'
import { useModalsStore } from '@shared/store/modals'
import { useRouterStore } from '@shared/store/router'
import {
  fetchCurrentUser,
  logout,
  updateGeolocSetting,
} from '@shared/http/api'
import { getAttributeTranslation } from '@shared/helpers/attributes'
import i18n from '@shared/i18n/vue-i18n'
import { DateTime } from 'luxon'
import { fromJS } from 'immutable'

// eslint-disable-next-line import/prefer-default-export
export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false)
  const user = ref(null)
  const sessionExpiredAt = ref(null)
  const reAuthenticationNeeded = ref(false)
  const sessionExpirationTimer = ref(0)

  // Stores
  const envStore = useEnvStore()
  const routerStore = useRouterStore()
  const modalsStore = useModalsStore()
  const filtersStore = useFiltersStore()

  function resetSessionExpiration() {
    sessionExpiredAt.value = null
    reAuthenticationNeeded.value = false

    if (sessionExpirationTimer.value) {
      clearTimeout(sessionExpirationTimer.value)
    }
  }

  // Set user's POI's address's coords as default filters
  function setDefaultGeolocFilter(user) {
    const poi = user
      ?.relationships
      ?.point_of_interest
    const addressAttributes = poi
      ?.relationships
      ?.address
      ?.attributes

    if (
      addressAttributes?.latitude
        && addressAttributes?.longitude
        && getAttributeTranslation(poi?.attributes?.title)
    ) {
      filtersStore.updateDefaultFilters({
        type: 'pointsOfInterest',
        values: {
          latitude: addressAttributes.latitude,
          longitude: addressAttributes.longitude,
          geoloc: getAttributeTranslation(poi.attributes.title),
          limit_to_a_radius: true,
          radius: 10,
        },
        refresh: true,
      })

      // Filter excursions by geoloc
      // only for travellers
      if (envStore.envName === 'app') {
        filtersStore.updateDefaultFilters({
          type: 'excursions',
          values: {
            latitude: addressAttributes.latitude,
            longitude: addressAttributes.longitude,
            geoloc: getAttributeTranslation(poi.attributes.title),
            limit_to_a_radius: false,
            radius: null,
          },
          refresh: true,
        })
      }

      // Filter excursions by geoloc
      // only for butler
      if (user.authorizations.access.dashboard_butler) {
        filtersStore.updateDefaultFilters({
          type: 'excursions',
          values: {
            latitude: addressAttributes.latitude,
            longitude: addressAttributes.longitude,
            geoloc: getAttributeTranslation(user.relationships.point_of_interest.attributes.title),
            limit_to_a_radius: true,
            radius: 50,
          },
          refresh: true,
        })
      }
    }
  }

  async function getAuthenticatedUser() {
    setReAuthenticationNeeded(false)

    await fetchCurrentUser().then(({ data }) => {
      if (data?.data?.id) {
        user.value = data.data
      }
    })

    const locale = user.value?.attributes?.locale
    if (locale) {
      i18n.global.locale.value = locale
    }

    isAuthenticated.value = (user.value !== null)
    setDefaultGeolocFilter(user.value)
  }

  // Sign out from SPA
  async function signOut() {
    window.Echo.leave(`App.Models.User.${user.value?.id}`)

    isAuthenticated.value = false
    user.value = null

    resetSessionExpiration()
  }

  // Try to sign out from API, then from SPA
  async function signOutFromApi() {
    return new Promise((resolve, reject) => {
      // Ignore if already logged out
      if (!isAuthenticated.value) {
        reject()
      }

      logout()
        .then(() => {
          signOut()
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  }

  async function redirectAuthenticatedUser() {
    if (
      envStore.envName === 'extranet'
      && (
        user.value.attributes.should_choose_plan
        || user.value.attributes.should_pay_subscription
      )
    ) {
      // Redirect user to subscriptions page if needed
      await routerStore.router.push({ name: 'subscriptions' })
    } else if (routerStore.afterLoginRedirectRoute) {
      // Redirect user to the specified path after login
      await routerStore.router.push(routerStore.afterLoginRedirectRoute)
    } else {
      await routerStore.router.push('/')
    }
  }

  function refreshSessionExpiration() {
    const sessionLifeTimeInMinutes = import.meta.env.VITE_SESSION_LIFETIME
    sessionExpiredAt.value = DateTime.now().plus({
      minutes: sessionLifeTimeInMinutes,
    })
  }

  function setUser(value) {
    user.value = value
  }

  function setReAuthenticationNeeded(value) {
    if (value) {
      // stop listening to incoming notifications
      window.Echo.leave(`App.Models.User.${user.value?.id}`)
    }
    reAuthenticationNeeded.value = value
  }

  function adaptUnreadNotificationsCounter(value) {
    const updatedUser = fromJS(user.value).toJS()
    updatedUser.attributes.unread_notifications_count += value
    user.value = updatedUser
  }

  async function refreshGeoloc(retryCallback) {
    return new Promise((resolve, reject) => {
      // Only in traveller app and if user has "around_me" geoloc setting's kind
      if (envStore.envName === 'app' && user.value.relationships?.geoloc_setting?.attributes?.kind === 'around_me') {
        navigator.geolocation.getCurrentPosition(
          // Geolocation success callback
          (position) => {
            const newLatitude = position.coords.latitude
            const newLongitude = position.coords.longitude

            // Update user's coords
            updateGeolocSetting({
              data: {
                type: 'usergeolocsettings',
                attributes: {
                  latitude: newLatitude,
                  longitude: newLongitude,
                },
              },
            }).then((response) => {
              // Save user geoloc setting in store
              const updatedUser = user.value
              updatedUser.relationships.geoloc_setting = response.data.data
              setUser(updatedUser)

              resolve()
            }).catch((error) => {
              reject(error)
            })
          },
          (error) => {
            // Show "geoloc failed" modal
            modalsStore.show('geoloc_failed')

            // Set "geoloc failed" retry callback
            modalsStore.setProperties({
              name: 'geoloc_failed',
              retry_callback: retryCallback ?? (async () => refreshGeoloc()), // Retry provided callback, or retry self
              error_code: error.code,
            })

            reject(error)
          },
        )
      } else {
        resolve()
      }
    })
  }

  function setSessionExpirationTimer(value) {
    sessionExpirationTimer.value = value
  }

  function clearSessionExpirationTimer() {
    sessionExpirationTimer.value = 0
  }

  return {
    isAuthenticated,
    reAuthenticationNeeded,
    user,
    sessionExpiredAt,
    sessionExpirationTimer,
    getAuthenticatedUser,
    redirectAuthenticatedUser,
    signOutFromApi,
    refreshSessionExpiration,
    setUser,
    setReAuthenticationNeeded,
    setSessionExpirationTimer,
    clearSessionExpirationTimer,
    signOut,
    adaptUnreadNotificationsCounter,
    refreshGeoloc,
  }
})
