<template>
  <header
    class="z-20 sticky top-0 w-full duration-300"
    :class="backgroundToUse"
  >
    <div class="max-w-screen-lg mx-auto h-12 py-8 px-6 flex items-center justify-between font-semibold duration-300">
      <!--
        Header left part
        Let a wrapper, so there is always
        a left block even if it is empty
      -->
      <div>
        <app-heading
          v-if="isHomePage"
          class="text-xl"
        >
          {{ welcomeMessage }}
        </app-heading>

        <!-- Back button -->
        <app-button
          v-if="displayBackButton"
          :icon-props="{
            library: 'coolicon',
            name: 'chevron_big_left',
            size: transparentBackground ? null : 'text-3xl'
          }"
          hide-label
          :hide-icon="false"
          :emphasis="`${transparentBackground ? 'high' : 'low'}`"
          :font-color-classes="`${transparentBackground ? 'text-white' : 'text-theme-500'}`"
          rounded="rounded-1/2"
          :shadow="transparentBackground ? null : 'shadow-none'"
          width="8"
          height="8"
          padding="p-0"
          class="leading-none"
          @click="appRouter.back()"
        />
      </div>

      <!-- Header right part -->
      <div class="flex items-center">
        <the-notifications
          v-if="authenticated"
          :display-bell="displayMainHeaderItems"
        />

        <div
          id="header-right"
        />
      </div>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import useAppRouter from '@shared/hooks/router'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'
import TheNotifications from '@shared/components/features/notifications/TheNotifications.vue'

const route = useRoute()
const store = useStore()
const appRouter = useAppRouter()

const authenticated = computed(() => (
  store.state.auth.authenticated
))

// ---------- BACKGROUND ----------

const transparentBackground = computed(() => (
  store.state.header.transparentBackground
))

const backgroundToUse = computed(() => (
  transparentBackground.value
    ? 'bg-transparent'
    : 'bg-body'
))

// ---------- NAVIGATION ----------

// First levels routes, accessible
// from navbar when authenticated
const mainNavigationRoutesNames = [
  'home',
  'index-excursions',
  'index-points-of-interest',
  'index-shares',
  'navigation',
]

// Routes that do not need a back button
const backButtonNotNeededRoutesNames = computed(() => (
  mainNavigationRoutesNames.concat([
    'auth',
    'login',
    'register',
    'password-reset',
  ])
))

const displayMainHeaderItems = computed(() => (
  // Is the current route one of the main
  // navigation routes
  mainNavigationRoutesNames.includes(route.name)
))

const displayBackButton = computed(() => (
  !backButtonNotNeededRoutesNames.value.includes(route.name)
))

// ---------- WELCOME MESSAGE ----------

const { t } = useI18n()

const isHomePage = computed(() => (
  route.name === 'home'
))

const welcomeMessage = computed(() => {
  let message = `${t('common.welcome')} !`

  if (store.state.auth.authenticated && store.state.auth.user?.attributes?.name) {
    message = `${t('common.welcome')}, ${store.state.auth.user.attributes.name} !`
  }

  return message
})
</script>
