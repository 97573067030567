import { ref } from 'vue'
import { defineStore } from 'pinia'
import { fetchLastPrivacyPolicy, fetchLastTermsCondition } from '@shared/http/api'

// eslint-disable-next-line import/prefer-default-export
export const useTermsStore = defineStore('terms', () => {
  const privacyPolicy = ref(null)
  const termsCondition = ref(null)

  // Methods
  async function getLastTerm() {
    await fetchLastPrivacyPolicy()
      .then(({ data }) => {
        if (data?.data?.id) privacyPolicy.value = data.data
      })

    await fetchLastTermsCondition()
      .then(({ data }) => {
        if (data?.data?.id) termsCondition.value = data.data
      })
  }

  return {
    privacyPolicy,
    termsCondition,
    getLastTerm,
  }
})
